/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import config from '../../../../config';
import { authTokenLocalStorage } from './local-storage';

const baseURL = `${config.apiPath}`;

export type APIResponse<Custom = { [key: string]: any }> = ({
  success: true;
} & Custom) | {
  success: false;
  message: string;
};

function processFetch<DataType = any>(res: Response) {
  return Promise.all([res.ok, res.json()])
    .then(([ok, response]) => {
      if (!ok || !response?.success) {
        return Promise.reject(response);
      }
      return Promise.resolve(response as DataType);
    });
}

function get<ResponseType = any>(url: string) {
  const token = authTokenLocalStorage.get();
  return fetch(`${baseURL}${url}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...token ? { Authorization: `Bearer ${token}` } : {},
    },
  }).then<ResponseType>(processFetch);
}

function post<ResponseType = any>(url: string, body = {}) {
  const token = authTokenLocalStorage.get();
  return fetch(`${baseURL}${url}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...token ? { Authorization: `Bearer ${token}` } : {},
    },
    credentials: 'include',
    body: JSON.stringify(body),
  }).then<ResponseType>(processFetch);
}

export default {
  get,
  post,
};
