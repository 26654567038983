/* eslint-disable import/prefer-default-export */
import config from '../../../../config';

const AUTH_TOKEN_KEY = `auth_token:${config.uniqueCode}`;
const AUTH_EXPIRED = `exp:${config.uniqueCode}`;

export const authTokenLocalStorage = {
  get: (): string | null => localStorage.getItem(AUTH_TOKEN_KEY),
  set: (value: string): void => localStorage.setItem(AUTH_TOKEN_KEY, value),
  remove: (): void => localStorage.removeItem(AUTH_TOKEN_KEY),
};

export const expLocalStorage = {
  get: (): string | null => localStorage.getItem(AUTH_EXPIRED),
  set: (value: string): void => localStorage.setItem(AUTH_EXPIRED, value),
  remove: (): void => localStorage.removeItem(AUTH_EXPIRED),
};