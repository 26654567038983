/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from './features/app/components/Loading';
// import PaymentTrueWallet from './features/payments/PaymentTrueWallet';
// import LoginToSeePointsPage from './pages/LoginToSeePointsPage';

const HomePage = lazy(() => import(/* webpackChunkName: "Home.page" */'./pages/HomePage'));
const WDFHomePage = lazy(() => import(/* webpackChunkName: "WDFHomePage.page" */'./pages/WDFHomePage'));
const CouponHomePage = lazy(() => import(/* webpackChunkName: "CouponHomePage.page" */'./pages/CouponHomePage'));

const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage.page" */'./pages/LoginPage'));
const LoginToSeePointsPage = lazy(() => import(/* webpackChunkName: "LoginToSeePointsPage.page" */'./pages/LoginToSeePointsPage'));
const LoginToSeePointsPageDesktop = lazy(() => import(/* webpackChunkName: "LoginToSeePointsPageDesktop.page" */'./pages/LoginToSeePointsPageDesktop'));
const OTPPage = lazy(() => import(/* webpackChunkName: "OTPPage.page" */'./pages/OTPPage'));
const PaymentSelection = lazy(() => import(/* webpackChunkName: "PaymentSelection.page" */'./pages/PaymentSelectionsPage'));
const PaymentCard = lazy(() => import(/* webpackChunkName: "PaymentCard.page" */'./features/payments/PaymentCard'));
const PaymentEWallet = lazy(() => import(/* webpackChunkName: "PaymentEWallet.page" */'./features/payments/PaymentEWallet'));
const PaymentMobileBanking = lazy(() => import(/* webpackChunkName: "PaymentMobileBanking.page" */'./features/payments/PaymentMobileBanking'));
const PaymentQRCode = lazy(() => import(/* webpackChunkName: "PaymentQRCode.page" */'./features/payments/PaymentQRCode'));
const NetSummaryPage = lazy(() => import(/* webpackChunkName: "ThankyouPage.page" */'./pages/NetSummaryPage'));
const AddDryDurationPage = lazy(() => import(/* webpackChunkName: "AddDryDurationPage.page" */'./pages/AddDryDurationPage'));
const PaymentTrueMoney = lazy(() => import(/* webpackChunkName: "PaymentTrueMoney.page" */'./features/payments/PaymentTrueMoney'));

const SomethingWentWrong = lazy(() => import(/* webpackChunkName: "SomethingWengWrong.png" */'./pages/SomethingWentWrong'));
const CannotAuth = lazy(() => import(/* webpackChunkName: "CannotAuth.png" */'./pages/CannotAuth'));
const OrderDetailError = lazy(() => import(/* webpackChunkName: "OrderDetailError.png" */'./pages/OrderDetailError'));
const AboutUs = lazy(() => import(/* webpackChunkName: "AboutUs.page" */'./pages/AboutUs'));
const NotFound = lazy(() => import(/* webpackChunkName: "NotFoundPage.page" */'./pages/NotFoundPage'));
const WelcomePage = lazy(() => import(/* webpackChunkName: "WelcomePage.page" */'./pages/WelcomePage'));
const PayPlusRedirector = lazy(() => import(/* webpackChunkName: "WelcomePage.page" */'./pages/PayPlusRedirector'));

const renderPageComponent = (Comp: React.FC) => (
  <Suspense
    fallback={(
      <div className="flex flex-col items-center justify-center w-full h-screen my-auto align-middle">
        <Loading color="#666666" width="20%" height="10%" />
        {/* <p className="text-center">กำลังโหลด...</p> */}
      </div>
    )}
  >
    <Comp />
  </Suspense>
);

type IRoute = {
  path: string;
  component: any;
};

const routes: IRoute[] = [
  {
    path: '/:machine_code/select-payment/card',
    component: renderPageComponent(PaymentCard),
  },
  {
    path: '/:machine_code/select-payment/e-wallet',
    component: renderPageComponent(PaymentEWallet),
  },
  {
    path: '/payplus',
    component: renderPageComponent(PayPlusRedirector),
  },
  {
    path: '/:machine_code/select-payment/true-money',
    component: renderPageComponent(PaymentTrueMoney),
  },
  {
    path: '/payment-qrcode',
    component: renderPageComponent(PaymentQRCode),
  },
  {
    path: '/payment-mobile-banking',
    component: renderPageComponent(PaymentMobileBanking),
  },
  {
    path: '/login',
    component: renderPageComponent(LoginPage),
  },
  {
    path: '/user-points',
    component: renderPageComponent(LoginToSeePointsPage),
  },
  {
    path: '/user-points/desktop',
    component: renderPageComponent(LoginToSeePointsPageDesktop),
  },
  {
    path: '/otp',
    component: renderPageComponent(OTPPage),
  },
  {
    path: '/about-us',
    component: renderPageComponent(AboutUs),
  },
  {
    path: '/something-went-wrong',
    component: renderPageComponent(SomethingWentWrong),
  },
  {
    path: '/can-not-auth',
    component: renderPageComponent(CannotAuth),
  },
  {
    path: '/order-detail-error',
    component: renderPageComponent(OrderDetailError),
  },
  {
    path: '/404-not-found',
    component: renderPageComponent(NotFound),
  },
  {
    path: '/',
    component: renderPageComponent(WelcomePage),
  },
  {
    path: '/:machine_code/select-payment',
    component: renderPageComponent(PaymentSelection),
  },
  {
    path: '/:machine_code/select-payment/:order_id',
    component: renderPageComponent(PaymentSelection),
  },
  {
    path: '/:machine_code/add-dry-duration',
    component: renderPageComponent(AddDryDurationPage),
  },
  {
    path: '/:machine_code/net-summary/:order_id',
    component: renderPageComponent(NetSummaryPage),
  },
  {
    path: '/:machine_code',
    component: renderPageComponent(HomePage),
  },
  {
    path: '/wdf/:machine_code',
    component: renderPageComponent(WDFHomePage),
  }, {
    path: '/coupon/:machine_code',
    component: renderPageComponent(CouponHomePage),
  },

  {
    path: '*',
    component: renderPageComponent(NotFound),
  },
];

const Routes: React.FC = () => {
  return (
    <Switch>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} exact>
          {route.component}
        </Route>
      ))}
    </Switch>
  );
};

export default Routes;
