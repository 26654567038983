import styled from 'styled-components';

export const HeadLabel = styled.p`
  font-size: 34;
  font-weight: 500;
  line-height: 51;
`;

export const ErrorMsg = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25;
  margin-top: 0.15rem;
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
`;
