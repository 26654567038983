import { atom } from 'recoil';

export enum UserType {
  Anonymous = 'Anonymous',
  Customer = 'Customer',
}

export interface TUser {
  points?: number;
  _id?: string;
  mobile_number?: string;
  user_type?: UserType;
}

export const initialUserValue: TUser = {
  _id: '',
  mobile_number: '',
  points: 0,
  user_type: UserType.Anonymous,
};

export const UserInfo = atom<TUser>({
  key: 'UserState',
  default: initialUserValue,
});
