import React from 'react';
import ReactLoading from 'react-loading';

interface Props {
  color: string;
  height: string;
  width: string;
}

const Loading: React.FC<Props> = (props) => {
  return (
    <ReactLoading
      type="balls"
      color={props.color}
      height={props.height}
      width={props.width}
    />
  );
};

export default Loading;
