import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import useWindowDimensions from './features/app/common/functions/use-window-dimensions';
import api, { APIResponse } from './features/app/common/utils/api';
import { authTokenLocalStorage, expLocalStorage } from './features/app/common/utils/local-storage';
import Loading from './features/app/components/Loading';
import MachineSchema, { TMachine } from './features/app/models/machine-schema';
import BranchSchema, { TBranch } from './features/app/models/branch-schema';
import { initialUserValue, TUser, UserInfo } from './features/app/models/user-schema';
import LoginToSeePointsPageDesktop from './pages/LoginToSeePointsPageDesktop';
// import ServiceIsNotAvailable from './pages/ServiceIsNotAvailable';

import Routes from './Routes';

const Container = styled.div`
  width: 414px;
  background-color: white;
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1366px) {
    max-height: 979px;
  }
`;

const preMachineData = (data: TMachine) => {

  return {
    machine: {
      _id: data.machine._id,
      machine_name: data.machine.machine_name,
      description: data.machine.description,
      machine_code: data.machine.machine_code,
      machine_remote_id: data.machine.machine_remote_id,
      machine_type: data.machine.machine_type,
      branch_id: {
        tags: data.machine.branch_id.tags,
        _id: data.machine.branch_id._id,
        branch_remote_id: data.machine.branch_id.branch_remote_id,
        initials: data.machine.branch_id.initials,
        name: data.machine.branch_id.name,
        english_name: data.machine.branch_id.english_name,
        location: {
          lat: data.machine.branch_id.location.lat,
          lng: data.machine.branch_id.location.lng,
          place_id: data.machine.branch_id.location.place_id,
        },
      },
      capacity: data.machine.capacity,
      reward_points: data.machine.reward_points,
      price: data.machine.price,
      initial_time: data.machine.initial_time,
      overtime_price: data.machine.overtime_price,
      overtime_minute: data.machine.overtime_minute,
    },
    status: data.status,
    promotion: data.promotion,
  };
};

const preUserData = (data: TUser) => {
  return {
    _id: data._id,
    mobile_number: data.mobile_number,
    points: data.points,
    user_type: data.user_type,
  };
};

const App: React.FC = () => {
  const history = useHistory();
  const token = authTokenLocalStorage.get();
  const pathName = window.location.pathname.split('/');
  const machineCode = pathName[1];
  const netSummaryPage = pathName.includes('net-summary');
  const { width } = useWindowDimensions();
  const [user, setUser] = useRecoilState(UserInfo);
  const [machineData, setMachineData] = useRecoilState(MachineSchema);
  const [branchData, setBranchData] = useRecoilState(BranchSchema);
  const [loading, setLoading] = useState(true);

  const handleFetchMachine = async () => {
    var exp = expLocalStorage.get();
    if (netSummaryPage) {
      // Redirect to summary page ignore verify token
    }
    else if (Number(exp) < new Date().getTime()) {
      expLocalStorage.remove();
      history.push('/login')
    }
    else if (pathName[1] === 'user-points') {
      if (pathName[2] && pathName[2] === 'desktop') {
        history.push('/user-points/desktop');
      } else {
        history.push('/user-points');
      }
    } else if (pathName[1].toUpperCase() === 'COUPON') {
      // setBranchData({
      //   branch_code: pathName[2]
      // });
    } else if (machineCode.length === 6) {
      try {
        const res = await api.get<APIResponse<{ data: TMachine }>>(`/machine/code-name/${machineCode}`);

        if (res.success) {
          const resMachine = preMachineData(res.data);
          setMachineData(resMachine);
        }
      } catch (e) {
        if (e.success === false && e.error_code == 'SOMETHING_WENT_WRONG') {
          history.push('/login')
        } else {
          history.replace('/something-went-wrong');
        }
      }
    } else if (machineCode.toUpperCase().indexOf('WDF-') > -1) {
      const xx = machineCode.toUpperCase().split('WDF-');
      const branchCode = xx[1];
      setBranchData({
        branch_code: branchCode
      });

      history.replace(`/wdf/${branchCode}`);
    } else if (pathName[1].toUpperCase() === 'WDF') {
      setBranchData({
        branch_code: pathName[2]
      });
    } else if (machineCode == '') {
      history.replace('/');
    } else if (machineCode !== 'payplus') {

      history.replace('/404-not-found');
    }
  };

  const handleFetchUserData = async () => {
    if (token) {
      try {
        const res = await api.get<APIResponse<{ user_info: TUser }>>('/auth/me');
        if (res.success) {
          const userData = preUserData(res.user_info);
          setUser(userData);
        } else {
          authTokenLocalStorage.remove();
          setUser(initialUserValue);
        }
      } catch (e) {
        authTokenLocalStorage.remove();
        setUser(initialUserValue);
        history.replace('/');
      }
    } else {
      await api.post('/auth-customer/anonymous');
      setUser(initialUserValue);
    }
  };

  useEffect(() => {
    const fetchMachine = handleFetchMachine();
    const fetchUser = handleFetchUserData();

    Promise.all([fetchMachine, fetchUser]).then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-screen my-auto align-middle">
        <Loading color="#666666" width="20%" height="10%" />
        {/* <p className="text-center">กำลังโหลด...</p> */}
      </div>
    );
  }

  if (pathName[2] && pathName[2] === 'desktop') {
    return (
      <LoginToSeePointsPageDesktop />
    );
  }

  return (
    <div className="flex flex-col">
      {width >= 1366 && !netSummaryPage && (
        <p className="self-center my-4 text-lg font-medium text-center bg-white text-danger">กรุณาเปิดใช้งานในบราวเซอร์ของโทรศัพท์<br />เพื่อประสบการณ์ที่ดีที่สุด</p>
      )}
      <div className="flex flex-col w-auto h-full min-h-screen bg-gray-400">
        <Container className="h-full mx-auto">
          {/* <ServiceIsNotAvailable /> */}
          <Routes />
        </Container>
      </div>
    </div>
  );
};

export default App;
