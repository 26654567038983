/* eslint-disable import/prefer-default-export */
export const FormattedMobileNumber = (newValue: string, currentValue: string): string => {
  let displayValue = newValue.replace(/\D/g, '');
  [10, 6, 3].forEach((index) => {
    if (displayValue.length >= index) {
      displayValue = `${displayValue.slice(0, index)} ${displayValue.slice(index)}`;
    }
  });
  if (
    currentValue
    && (currentValue.length - newValue.length) === 1
    && (newValue.length === 3 || newValue.length === 7 || newValue.length === 14)
  ) {
    displayValue = displayValue.slice(0, newValue.length - 1);
  }
  return displayValue;
};

export const FormattedMobileNumberWithDash = (number?: string): string => {
  if (!number || number === '') return '-';
  const threeFirstDegits = number.substring(0, 3);
  const threeSecondDegits = number.substring(3, 6);
  const leftDegits = number.substring(6, number.length);
  const formatted = `${threeFirstDegits}-${threeSecondDegits}-${leftDegits}`;
  return formatted;
};

export const FormattedMobileNumberWithSpace = (number?: string): string => {
  if (!number || number === '') return '-';
  const threeFirstDegits = number.substring(0, 3);
  const threeSecondDegits = number.substring(3, 6);
  const leftDegits = number.substring(6, number.length);
  const formatted = `${threeFirstDegits} ${threeSecondDegits} ${leftDegits}`;
  return formatted;
};
