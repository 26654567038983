import { atom } from 'recoil';

export interface TMachine {
  machine: {
    _id: string;
    machine_name: number;
    description: string;
    machine_code: number;
    machine_remote_id: number;
    machine_type: string;
    branch_id: {
      tags: string[];
      _id: string;
      branch_remote_id: number;
      initials: number;
      name: string;
      english_name: string;
      location: {
        lat: number;
        lng: number;
        place_id: string;
      };
    };
    capacity: number;
    reward_points: number;
    price: number;
    initial_time: number;
    overtime_price: number;
    overtime_minute: number;
  };
  status: string;
  promotion?: {
    id?: string,
    discount?: number,
    desc?: string,
    discount_type?: string,
  };
}

const MachineSchema = atom<TMachine>({
  key: 'MachineSchema',
  default: {
    machine: {
      _id: '',
      machine_name: 0,
      description: '',
      machine_code: 0,
      machine_remote_id: 0,
      machine_type: '',
      branch_id: {
        tags: [''],
        _id: '',
        branch_remote_id: 0,
        initials: 0,
        name: '',
        english_name: '',
        location: {
          lat: 0,
          lng: 0,
          place_id: '',
        },
      },
      capacity: 0,
      reward_points: 0,
      price: 0,
      initial_time: 0,
      overtime_price: 0,
      overtime_minute: 0,
    },
    status: '',

  },
});

export default MachineSchema;
