import { atom } from 'recoil';

export interface TLocationInfo {
    lat: number;
    lng: number;
    place_id: string;
    post_code: string;
}

export interface TBranch {
    _id?: string;
    tags?: string[];
    initials?: string;
    name?: string;
    english_name?: string;
    store_type?: string;
    image?: string;
    branch_code?: string;
    location?: TLocationInfo;
}


const BranchSchema = atom<TBranch>({
    key: 'BranchSchema',
    default: {
        _id: '',
        tags: [],
        initials: '',
        name: '',
        english_name: '',
        store_type: '',
        image: '',
        branch_code: '',
    },
});

export default BranchSchema;
