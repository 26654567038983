import React, {
  useState,
} from 'react';
import { FormattedMobileNumberWithDash } from '../features/app/common/functions/format-phone-number';
import useWindowDimensions from '../features/app/common/functions/use-window-dimensions';
import handleVerifyMobileNumber from '../features/app/common/functions/verify-mobile-number';

import { InputBox } from '../features/app/common/input';
import { ErrorMsg } from '../features/app/common/labels';
import api, { APIResponse } from '../features/app/common/utils/api';
import Loading from '../features/app/components/Loading';
import { TUser, UserType } from '../features/app/models/user-schema';

enum DisplayState {
  MOBILE_NUMBER_INPUT = 'MOBILE_NUMBER_INPUT',
  OTP_INPUT = 'OTP_INPUT',
  SHOW_POINTS = 'SHOW_POINTS',
}

const LoginToSeePointsPageDesktop: React.FC = () => {
  const { width } = useWindowDimensions();
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOTP] = useState('');
  const [otpRefCode, setOTPRefCode] = useState('');
  const [displayState, setDisplayState] = useState(DisplayState.MOBILE_NUMBER_INPUT);
  const [isMobileNumberError, setIsMobileNumberError] = useState(false);
  const [isOTPError, setIsOTPError] = useState(false);
  const [isRequestOTPError, setIsRequestOTPError] = useState(false);
  const [isConfirmOTPError, setIsConfirmOTPError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<TUser>({
    _id: '',
    mobile_number: '',
    points: 0,
    user_type: UserType.Anonymous,
  });

  const handleRequestOTP = async () => {
    setIsLoading(true);
    const verifiedMobileNumber = handleVerifyMobileNumber(mobileNumber);
    if (verifiedMobileNumber) {
      try {
        const res = await api.post<APIResponse<{ success: boolean; reference_code: string }>>('/auth-customer/request-otp', { mobile_number: mobileNumber });
        if (res.success) {
          setTimeout(() => {
            setIsLoading(false);
            setIsRequestOTPError(false);
            setOTPRefCode(res.reference_code);
            setDisplayState(DisplayState.OTP_INPUT);
          }, 1500);
        } else {
          setIsMobileNumberError(false);
          setIsLoading(false);
          setIsRequestOTPError(true);
        }
      } catch (e) {
        setIsMobileNumberError(false);
        setIsLoading(false);
        setIsRequestOTPError(true);
      }
    } else {
      setIsLoading(false);
      setIsMobileNumberError(true);
    }
  };

  const handleConfirmOTP = async () => {
    if (!Number(otp)) {
      setIsOTPError(true);
    } else {
      try {
        setIsLoading(true);
        const res = await api.post<APIResponse<{ success: boolean; access_token: string; user_info: TUser }>>('/auth-customer/confirm-otp', { mobile_number: mobileNumber, otp_code: otp });
        if (res.success) {
          setUser(res.user_info);
          setIsLoading(false);
          setIsOTPError(false);
          setIsConfirmOTPError(false);
          setDisplayState(DisplayState.SHOW_POINTS);
        } else {
          setIsOTPError(false);
          setIsLoading(false);
          setIsConfirmOTPError(true);
        }
      } catch (e) {
        setIsOTPError(false);
        setIsLoading(false);
        setIsConfirmOTPError(true);
      }
    }
  };

  const handleSetValue = (value: string, valueType = 'mobileNumber' || 'otp') => {
    let replaced = value;
    replaced = replaced.replaceAll(',', '');
    replaced = replaced.replaceAll('.', '');
    replaced = replaced.replaceAll('-', '');
    if (valueType === 'mobileNumber') { setMobileNumber(replaced); }
    if (valueType === 'otp') { setOTP(replaced); }
  };

  return (
    <div className="relative flex flex-col w-full h-screen bg-white">
      <div className="absolute top-0 z-10 flex justify-end w-full p-4">
        <img src="/static/images/wash-xpress-logo-text.svg?" alt="WashXpress" className="sm:w-24 md:w-28" />
      </div>
      <div className="flex flex-col mx-auto sm:mt-24 md:mt-32">
        <img className="mx-auto mb-2 sm:w-14 md:w-16 mlg:w-20" src="/static/icons/icon-points-pink.svg" alt="Points" />
        <p className="mb-0 font-semibold tracking-wide text-center sm:text-lg md:text-xl mlg:text-3xl text-primary">ตรวจสอบ Points</p>
        <div className="flex flex-col w-full">
          {displayState === DisplayState.MOBILE_NUMBER_INPUT && (
            <div className="w-full mt-0">
              <p className="my-0 font-normal tracking-wide text-center sm:mb-6 md:mb-10 sm:text-base md:text-lg text-primary">กรอกหมายเลขโทรศัพท์เพื่อเข้าสู่ระบบ</p>
              <p className="mb-4 font-bold tracking-wide text-center sm:text-base md:text-lg text-primary">หมายเลขโทรศัพท์</p>
              <InputBox
                maxLength={10}
                inputMode="numeric"
                type="text"
                value={mobileNumber}
                onChange={(e) => handleSetValue(e.target.value, 'mobileNumber')}
                placeholder="กรอกหมายเลขโทรศัพท์"
                className="text-center"
              />
              {isMobileNumberError && <ErrorMsg>เบอร์โทรศัพท์ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง</ErrorMsg>}
              {isRequestOTPError && <ErrorMsg>ไม่สามารถขอ OTP ได้ กรุณาตรวจสอบเบอร์โทรศัพท์<br />หรือลองใหม่อีกครั้ง</ErrorMsg>}
              <button className={`px-4 py-2 mt-4 w-full text-lg text-white rounded-full cursor-pointer ${mobileNumber.length < 10 ? 'bg-gray-500' : 'bg-primary'}`} type="button" onClick={() => mobileNumber.length === 10 && handleRequestOTP()}>
                {isLoading ? <div className="flex justify-center w-full h-full"><Loading color="#FFFFFF" height="10%" width="10%" /></div> : 'ถัดไป'}
              </button>
            </div>
          )}
          {displayState === DisplayState.OTP_INPUT && (
            <div className="flex flex-col w-full mt-2">
              <div className="flex flex-col w-full my-0 sm:text-sm md:text-base text-primary">
                <p className="mb-0 text-center">กรุณากรอกรหัส OTP ที่ส่งไปที่หมายเลข</p>
                <p className="mb-0 text-center">{FormattedMobileNumberWithDash(mobileNumber)} (Ref code: {otpRefCode})</p>
                <p className="mb-4 text-center">เพื่อยืนยันตัวตน</p>
              </div>
              <p className="mb-4 font-bold tracking-wide text-center sm:text-base md:text-lg text-primary">รหัสOTP</p>
              <InputBox
                maxLength={6}
                inputMode="numeric"
                type="text"
                value={otp}
                onChange={(e) => handleSetValue(e.target.value, 'otp')}
                placeholder="กรอกรหัส OTP"
                className="text-center"
              />
              {isOTPError && <ErrorMsg>รหัส OTP ต้องเป็นตัวเลขเท่านั้น กรุณาตรวจสอบอีกครั้ง</ErrorMsg>}
              {isConfirmOTPError && <ErrorMsg>ไม่สามารถยืนยัน OTP ได้ กรุณาตรวจสอบแล้วลองอีกครั้ง</ErrorMsg>}
              <button className={`px-4 w-full py-2 mt-4 text-lg text-white rounded-full cursor-pointer ${otp.length < 6 ? 'bg-gray-500' : 'bg-primary'}`} type="button" onClick={() => otp.length === 6 && handleConfirmOTP()}>
                {isLoading ? <div className="flex justify-center w-full h-full"><Loading color="#FFFFFF" height="10%" width="10%" /></div> : 'ยืนยัน'}
              </button>
              <span className="mx-auto font-bold tracking-wide text-center cursor-pointer sm:mt-2 md:mt-4 sm:text-base md:text-lg text-primary" onClick={handleRequestOTP}>
                ส่งรหัสใหม่
              </span>
            </div>
          )}
          {displayState === DisplayState.SHOW_POINTS && (
            <>
              <div className="flex flex-col w-full mt-4">
                <div className="flex flex-col w-full px-4 py-5 rounded-2xl" style={{ background: '#F6CDD7' }}>
                  <div className="flex flex-col w-full my-auto">
                    <span className="flex justify-between w-full mb-1 sm:text-sm md:text-base text-primary">
                      <p className="mb-0 mr-8">เบอร์โทรสมาชิก: </p>
                      <p className="mb-0 font-bold">{FormattedMobileNumberWithDash(user.mobile_number)}</p>
                    </span>
                    <span className="flex justify-between w-full sm:text-sm md:text-base text-primary">
                      <p className="mb-0 mr-8">Points สะสม: </p>
                      <p className="mb-0 font-bold">{Number(user.points).toLocaleString()} Points</p>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="absolute bottom-0 z-10 w-full sm:h-44 md:h-52">
        {/* <svg className="w-full h-full" preserveAspectRatio="none" viewBox="0 0 1379 354" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M325.319 279.74C77.7637 268.764 -56.1658 149.178 -92.1861 90.7574L-159 416.229L1293.27 714.354L1349.55 440.194C1299.09 322.365 1133.01 247.905 1029.09 226.573C836.218 182.784 691.791 210.28 606.856 234.783C493.982 267.346 401.348 283.111 325.319 279.74Z" fill="#D3D9EB" />
        </svg> */}
        <svg className="w-full h-full" preserveAspectRatio="none" viewBox="0 0 360 137" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M85.1046 72.8374C20.6473 69.9795 -14.2246 38.8423 -23.6034 23.631L-41 108.376L337.134 186L351.788 114.615C338.649 83.9359 295.407 64.5483 268.35 58.9941C218.13 47.5924 180.525 54.7516 158.41 61.1317C129.02 69.6104 104.901 73.7152 85.1046 72.8374Z" fill="#D3D9EB" />
        </svg>
      </div>
      <div className="absolute bottom-0 z-20 w-full sm:h-32 md:h-40">
        {/* <svg className="w-full h-full" preserveAspectRatio="none" viewBox="0 0 1440 241" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M433.452 188.385C195.604 226.32 44.7138 138.637 -1.00049 90.0534V413H1440V140.966C1368.93 38.6423 1196.25 0.207902 1093.14 0.207902C900.938 -3.78644 768.801 50.6217 692.722 90.5525C591.616 143.619 506.5 176.734 433.452 188.385Z" fill="#21409A" />
        </svg> */}
        <svg className="w-full h-full" preserveAspectRatio="none" viewBox="0 0 375 108" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M113.06 49.2629C51.1636 59.1829 11.8965 36.2537 0 23.5491V108H375V36.8629C356.504 10.105 311.566 0.0543666 284.734 0.0543666C234.717 -0.990158 200.33 13.2376 180.531 23.6796C154.22 37.5564 132.07 46.2162 113.06 49.2629Z" fill="#21409A" />
        </svg>
      </div>
      {displayState === DisplayState.SHOW_POINTS && (
        <>
          <div className="absolute z-30 flex my-0 sm:flex-col mlg:flex-row bottom-4 left-4">
            <div className="flex flex-col my-0 mr-4">
              <p className="mb-0 font-bold tracking-wide text-white sm:text-xxs md:text-xs lg:text-sm xl:text-xl">โหลด WashXpress</p>
              <p className="mb-0 font-normal tracking-wide text-white sm:text-xxs md:text-xs lg:text-sm xl:text-xl">เพื่อใช้ Points ซักฟรีได้แล้วนะ</p>
            </div>
            <div className="flex my-0">
              <div className="mt-auto mr-2 cursor-pointer sm:w-10 md:w-12 mlg:w-20 xl:w-28" onClick={() => { window.open('https://apps.apple.com/th/app/washxpress/id1577335601', '_blank'); }}>
                <img className="w-full" src="/static/images/apple-app-store.svg" alt="App Store" />
              </div>
              <div className="mt-auto cursor-pointer sm:w-12 md:w-14 mlg:w-24 xl:w-32" onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.laundryyou.washxpress', '_blank'); }}>
                <img className="w-full" src="/static/images/google-play-store.svg" alt="Google Play Store" />
              </div>
            </div>
          </div>
          {width >= 360 && <img className="absolute bottom-0 z-30 w-2/5 sm:right-4 mlg:right-10" src="/static/images/woman-using-iphone-x.png" alt="Download WashXpress" />}
        </>
      )}
    </div>
  );
};

export default LoginToSeePointsPageDesktop;
