/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const InputBox = styled.input`
  font-size: 1rem;
  line-height: 1.5rem;
  align-items: center;
  /* height: 100%; */
  height: 2.35rem;
  width: 100%;
  border-width: 1px;
  border-color: #21409A;
  outline: none;
  border-radius: 1.5rem;
  padding: 0.05rem 0.5rem;
`;

export const OTPInput = styled.input`
  font-size: 0.85rem;
  line-height: 1.5rem;
  font-size: 18px;
  text-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-width: 2px;
  border-color: #21409A;
  outline: none;
  border-radius: 0.5rem;
  padding: 0.05rem 0.5rem;
`;
