const env = process.env.REACT_APP_ENV;

const defaultConfig = {
  uniqueCode: 'j3ygj',
  apiPath: 'https://staging.app.washxpressth.com/api',
  //apiPath: 'http://localhost:3001',
  urlScheme: 'washxpress-uat://',
  omise_public_key: 'pkey_test_5o2atdmwwubmdebbh6q',
};

const productionConfig = {
  apiPath: 'https://app.washxpressth.com/api',
  urlScheme: 'washxpress://',
  omise_public_key: 'pkey_5oo7ma2n3g38cjoingk',
};

const stagingConfig = {
  apiPath: 'https://staging.app.washxpressth.com/api',
};

const config = {
  ...defaultConfig,
  ...(env === 'production' ? productionConfig : {}),
  ...(env === 'staging' ? stagingConfig : {}),
};

export default config;
